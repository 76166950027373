import React, { Component } from 'react';

import './main.css';

export default class Header extends Component {
  state = {
    activeMenu: false,
    darkHeader: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  _handleScroll = () => {
    const that = this;
    const offsetY = window.scrollY;

    if (offsetY > 50) {
      that.setState({ darkHeader: true })
    } else {
      that.setState({ darkHeader: false })
    }
  }

  _toggleMobileMenu = () => {
    this.setState({ activeMenu: !this.state.activeMenu })
  }

  render() {
    const { state } = this;
    const headerClasses = `${state.activeMenu ? 'menu-active' : ''} ${state.darkHeader ? 'dark' : ''}`

    return (
      <header className={headerClasses}>
        <div className="header-wrapper">
          <span className="logo-text">ATTIC RECORDS</span>
          <ul className="nav-main">
            <li><a href="#home">HOME</a></li>
            <li><a href="#about">ABOUT</a></li>
            <li><a href="#albums">ALBUMS</a></li>
            <li><a href="#lex">L.E.X</a></li>
            <li><a href="#jackdavid">JACK DAVID</a></li>
            <li><a href="#videos">VIDEOS</a></li>
            <li><a href="#contact">CONTACT</a></li>
          </ul>
          <button onClick={this._toggleMobileMenu} className="menu">Menu</button>
        </div>

      </header>
    );
  }
}
