import React, { Component } from 'react';
import {RichText} from 'prismic-reactjs';
import api from '../../utils/api';

import './main.css';

const API = new api();

const LinkGroup = (props) => {
  const links = props.links;

  return (
    <div className="social-links">
      {links.map((link, index) => {
        const url = link.social_media_link.url;

        return (
          <a key={index} href={url} rel="noopener noreferrer" target="_blank">Link</a>
        )
      })}
    </div>
  )
}

const ImageGroup = (props) => {
  const images= props.data;

  return (
    <div className="image-group">
      {images.map((item,index) => {
        const image = item.artist_image;

        return <img key={index} src={image.url} alt={image.alt} />
      })}
    </div>
  )
}

const ArtistWrapper = (props) => {
  const data = props.data.data;
  const id = props.index === 0 ? 'lex' : 'jackdavid'

  return (
    <div id={id} className="artist-outer-wrapper" style={{backgroundImage: "url(" + data.artist_background.url + ")"}}>
      <div className="artist-wrapper" >
        <img className="artist-logo" alt="Artist logo" src={data.artist_logo.url} />
        <div className="artist-message">{RichText.render(data.artist_message)}</div>
        <div className="artist-promo-title">{RichText.render(data.artist_promo_title)}</div>
        <iframe title={`Artist playlist ${id}`} src={data.artist_promo_link.url} width="100%" height="380" frameBorder="0" allowtransparency="true" />
        <LinkGroup links={data.social_links} />
        <ImageGroup data={data.artist_image_group} />
      </div>
    </div>
  )
}



export default class Promo extends Component {
  state = {
    data: null,
    isLoading: null
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    API.getDocType('artist').then((res) => {
      const data = res.results;

      this.setState({ data, isLoading: false });

    }).catch((err) => {
      console.log(err);
      this.setState({ data: undefined, isLoading: false });
    })
  }

  render() {
    const {data} = this.state;

    return (
      <div className="artist-container">
        {(!this.state.isLoading && data) && data.map((item, index) => {
          return <ArtistWrapper key={index} data={item} index={index} />
        })}
      </div>
    );
  }
}
