import React, { Component } from 'react';
import './App.css';
import './icomoon.css';

import Header from './components/header/Header';
import Hero from './components/hero/Hero';
import About from './components/about/About';
import Promo from './components/promo/Promo';
import Artist from './components/artist/Artist';
import Slider from './components/videos/Slider';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Hero />
        <About id="about"/>
        <Promo />
        <Artist />
        <Slider />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default App;
