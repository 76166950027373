import * as firebase from "firebase/app";
import 'firebase/database';

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDeRbZJBNMMhu20wENiXu_k5g39QYgY0Hc",
  authDomain: "attic-records-app.firebaseapp.com",
  databaseURL: "https://attic-records-app.firebaseio.com",
  projectId: "attic-records-app",
  storageBucket: "attic-records-app.appspot.com",
  messagingSenderId: "639249553042"
};

firebase.initializeApp(config);

export default firebase;
