import React, { Component } from 'react';
import {RichText} from 'prismic-reactjs';
import api from '../../utils/api';

import './main.css';

const API = new api();

export default class About extends Component {
  state = {
    data: null,
    isLoading: null
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    API.getDocType('about').then((res) => {
      const data = res.results[0].data;

      this.setState({ data, isLoading: false });

    }).catch((err) => {
      console.log(err);
      this.setState({ data: undefined, isLoading: false });
    })
  }

  render() {
    const {data} = this.state;

    return (
      <div id="about" className="about-container">
        {(!this.state.isLoading && data) && (
          <div className="about-wrapper">
            <div className="logo">
              <img alt="Attic Records Logo" src={data.about_image.url} />
            </div>
            <div className="about-content">
              <div className="callout-title">{RichText.render(data.about_title)}</div>
              <div className="callout-message">{RichText.render(data.about_text)}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
