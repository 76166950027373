import React, { Component } from 'react';
import {RichText} from 'prismic-reactjs';
import api from '../../utils/api';
import Slick from 'react-slick';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './main.css';
const bg = 'https://prismic-io.s3.amazonaws.com/atticrecords%2F6e537045-f1a5-443e-9f95-ee172fdcf21e_lex-vid.jpg'

const API = new api();

export default class Slider extends Component {
  state = {
    data: null,
    isLoading: null
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    API.getDocType('video_slider').then((res) => {
      const data = res.results[0].data;

      this.setState({ data, isLoading: false });

    }).catch((err) => {
      console.log(err);
      this.setState({ data: undefined, isLoading: false });
    })
  }

  render() {
    const {data} = this.state;
    const settings = {
      autoplay: false,
      autoplaySpeed: 5000
    }
    return (
      <div id="videos" className="videos-container" style={{'backgroundImage': `url(${bg})`}}>
        <Slick {...settings}>
          {(!this.state.isLoading && data) ? data.video_slide.map((item, index) => {
            return (
              <div key={index} className="video-slide">
                <div className="slide-title">{RichText.render(item.video_title)}</div>
                <iframe title={item.video_title[0].text} src={item.youtube_embed_url.url} scrolling="no" frameBorder="0" allowFullScreen></iframe>
              </div>
            )
          }): null}
        </Slick>
      </div>
    );
  }
}
