import React, { Component } from 'react';
import {RichText} from 'prismic-reactjs';
import api from '../../utils/api';

import './main.css';

const API = new api();

const LinkGroup = (props) => {
  const links = props.links;

  return (
    <div className="links-container">
      {links.map((link, index) => {
        const url = link.streaming_service_link.url;
        const title = link.link_title[0].text;

        return (
          <a key={index} href={url} rel="noopener noreferrer" target="_blank">{title}</a>
        )
      })}
    </div>
  )
}

export default class Promo extends Component {
  state = {
    data: null,
    isLoading: null
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    API.getDocType('promotion').then((res) => {
      const data = res.results[0].data;

      this.setState({ data, isLoading: false });

    }).catch((err) => {
      console.log(err);
      this.setState({ data: undefined, isLoading: false });
    })
  }

  render() {
    const {data} = this.state;

    return (
      <div id="albums" className="promo-container">
        {(!this.state.isLoading && data) && (
          <div className="promo-wrapper">
            <div className="promo-title">{RichText.render(data.promo_title)}</div>
            <img className="promo-image" alt={data.promo_title} src={data.promo_image.url}/>
            <div className="promo-message">{RichText.render(data.promo_message)}</div>
            <LinkGroup links={data.streaming_links} />
          </div>
        )}
      </div>
    );
  }
}
