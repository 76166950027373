import Prismic from 'prismic-javascript';

class api {
  constructor() {
    this.url = 'https://atticrecords.cdn.prismic.io/api/v2';
  }

  getDocType(type) {
    return new Promise((resolve, reject) => {
      Prismic.getApi(this.url).then(function(api) {
        return api.query(Prismic.Predicates.at('document.type', type)); // An empty query will return all the documents
      }).then(function(response) {
        resolve(response);
      }, function(err) {
        reject(err)
      });
    })
  }

  getDocById(id) {
    return new Promise((resolve, reject) => {
      Prismic.getApi(this.url).then(function(api) {
        return api.query(Prismic.Predicates.at('document.id', id)); // An empty query will return all the documents
      }).then(function(response) {
        resolve(response);
      }, function(err) {
        reject(err)
      });
    })
  }

  getDocUid(uid) {
    return new Promise((resolve, reject) => {
      Prismic.getApi(this.url).then(function(api) {
        return api.query(Prismic.Predicates.at('my.tile.uid', uid)); // An empty query will return all the documents
      }).then(function(response) {
        resolve(response.results);
      }, function(err) {
        reject(err)
      });
    })
  }
}

export default api
