import React from 'react';

import './main.css';

const Footer = (props) => {
  const date = new Date();

  return (
    <footer>
      <div className="footer-content">
        <p>ALL RIGHTS RESERVED. COPYRIGHT © {date.getFullYear()}. Attic Records, LLC. Built by <a href="https://jordanmel.com">Jordan Melendez</a></p>
      </div>
    </footer>
  )
}

export default Footer
