import React, { Component } from 'react';
import {RichText} from 'prismic-reactjs';
import api from '../../utils/api';
import Form from './Form';

import './main.css';

const API = new api();

export default class Slider extends Component {
  state = {
    data: null,
    isLoading: null
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    API.getDocType('contact').then((res) => {
      const data = res.results[0].data;
      
      this.setState({ data, isLoading: false }, () => {

      });

    }).catch((err) => {
      console.log(err);
      this.setState({ data: undefined, isLoading: false });
    })
  }

  render() {
    const {data} = this.state;

    return (
      <div id="contact" className="contact-container" >
        {(!this.state.isLoading && data) && (
          <div className="contact-wrapper">
            <div className="contact-title">{RichText.render(data.contact_title)}</div>
            <div className="contact-message">{RichText.render(data.contact_message)}</div>
            <Form />
          </div>
        )}
      </div>
    );
  }
}
