import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "//atticrecords.us13.list-manage.com/subscribe/post?u=25d3a402f5ddb02a9952cbe9c&amp;id=01155f0b4c";

const MailchimpForm = (props) => {
  return (
    <div className="newsletter-signup">
      <MailchimpSubscribe url={url}/>
    </div>
  )
}

export default MailchimpForm
