import React, { Component } from 'react';
import {RichText} from 'prismic-reactjs';
import api from '../../utils/api';
import MailchimpForm from './MailchimpForm';
import './main.css';

const API = new api();

export default class Hero extends Component {
  state = {
    data: null,
    isLoading: null
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    API.getDocType('hero').then((res) => {
      const data = res.results[0].data;

      this.setState({ data, isLoading: false });

    }).catch((err) => {
      console.log(err);
      this.setState({ data: undefined, isLoading: false });
    })
  }

  render() {
    const {data} = this.state;

    return (
      <div id="home" className="hero-container">
        { (!this.state.isLoading && data) && (
          <div className="hero-container-wrapper" style={{
            backgroundImage: "url(" + data.background_image.url + ")"
          }}>
            <div className="intro-content">
              <div className="callout-title">{RichText.render(data.callout_title)}</div>
              <div className="callout-message">{RichText.render(data.callout_message)}</div>
              <MailchimpForm />
              <div className="promo-video" dangerouslySetInnerHTML={{__html: data.promo_video.html}}></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
